import { css } from 'styled-components'
import { styled, theme } from '../STheme'
import { ButtonVariants, IButton } from './IButton'
const stylesClose = (): Record<ButtonVariants, string> => {
  return {
    g1: `
      background: ${theme.colors.white[1]};
      border: 1px solid ${theme.colors.green[1]};
      :hover {
        background: ${theme.colors.gray[2]};
      }
      p {
        color: ${theme.colors.green[1]};
      }
    `,
    g2: `
      background: ${theme.colors.green[1]};
      :hover {
        background: ${theme.colors.green[2]};
      }
      p {
        color: ${theme.colors.white[1]};
      }
    `,

    g3: `
      background: ${theme.colors.white[1]};
      p {
        color: ${theme.colors.green[1]};
      }
      :hover {
        background: ${theme.colors.green[1]};
        p, i {
          color: ${theme.colors.white[1]};
        }
      }
    `,
    w1: `
      border: none;
      outline: none;
      min-width: auto;
    `,
    w2: `
    background: ${theme.colors.white[1]};
    :hover {
        background: ${theme.colors.gray[2]};
      }
    `,
    gr1: `
      background: ${theme.colors.gray[3]};
      :hover {
        background: ${theme.colors.gray[5]};
        p {
          color: ${theme.colors.white[1]};
        }
      }
    `,
  }
}
export const IconContainer = styled.div``

export const ButtonContainer = styled.button<IButton>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  min-height: ${({ mh }) => (mh ? mh : 'auto')};
  min-width: ${({ mw }) => (mw ? `${mw}px` : 'auto')};
  height: ${({ h }) => (h ? h : 'auto')};
  border-radius: ${({ rds }) => (rds ? rds : '5px')};
  font-size: ${(p) => (p.fs ? p.fs : p.theme.fontSizes[15])};
  padding-left: ${({ pl }) => (pl ? `${pl}px` : 0)};
  padding-right: ${({ pr }) => (pr ? `${pr}px` : 0)};
  padding-top: ${({ pt }) => (pt ? `${pt}px` : 0)};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : 0)};
  transition: 0.5s;
  border: ${({ br }) => br || 'none'};
  color: ${(p) => (p.color ? p.color : theme.colors.dark[1])};
  ${({ bgColor }) =>
    bgColor &&
    css`
      background: ${bgColor};
    `}
  ${({ m, mt, mr, mb, ml }) =>
  (m || mt || mr || mb || ml) &&
    css`
      margin: ${m ? `${m}px` : `${mt || 0}px ${mr || 0}px ${mb || 0}px ${ml || 0}px`};
    `}
  ${({ p, pt, pr, pb, pl }) =>
    (p || pt || pr || pb || pl) &&
    css`
      padding: ${p ? `${p}px` : `${pt || 0}px ${pr || 0}px ${pb || 0}px ${pl || 0}px`};
    `}
  ${({ hvColor, hvBg }) =>
    (hvColor || hvBg) &&
    css`
      &:hover {
        color: ${hvColor};
        background-color: ${hvBg}!important;
      }
    `};
  ${({ loading, disabled, isDownloading }) => {
    if (loading || disabled) {
      return `
    cursor: not-allowed;
    `
    }
    if (isDownloading) {
      return `
        pointer-events: none;
      `
    }
    return `
  cursor: pointer;
  `
  }}
  svg {
    margin-left: ${(p) => (p.hasText && p.iconPosition === 'after' ? '10px' : '0')};
    margin-right: ${(p) => (p.hasText && p.iconPosition === 'before' ? '10px' : '0')};
  }

  :focus {
    outline: none;
  }
  ${({ variant }) => stylesClose()[variant as ButtonVariants]}
  width: ${({ w }) => (w ? w : 'auto')};
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  svg {
    margin-left: 0;
  }
`

export const IconWrapper = styled.div`
  margin-bottom: 2px;
  display: flex;
  align-items: center;
`
